<script>
  import constants from './constants';
  import { client } from './graph/client';
  import gql from 'graphql-tag';
  import { onMount } from 'svelte';
  import jwtDecode from 'jwt-decode';
  import queryString from 'query-string';

  let username = '';
  let password = '';

  let token;
  let error;

  function sanitize(string) {
    const map = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#x27;',
      '/': '%2F',
    };
    const reg = /[&<>"'/]/gi;
    return string.replace(reg, match => map[match]);
  }

  onMount(async () => {
    const q = queryString.parse(location.search);
    console.log(constants, q);
    token = localStorage.getItem('token');
    if (typeof token !== 'undefined') {
      let jwt;
      console.log(token);
      try {
        jwt = jwtDecode(token);
      } catch (e) {
        if (e.message.includes('Invalid')) {
          await fetch('/logout', {
            method: 'GET',
          });
          localStorage.removeItem('token');
        }
        console.log('login.svelte jwt error', jwt, e);
      }

      if (token) {
        try {
          const tokenResponse = await fetch('/login', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token }),
          });

          if (tokenResponse.status === 418) {
            fetch(constants.graphql, {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                query: `mutation {
                      refreshAuthentication(input: { username: "${jwt.username}", token: "${jwt.refresh_token}" }) {
                      jwtToken
                      }
                    }`,
              }),
            }).then(async response => {
              const json = await response.json();
              const thisToken = json.data.refreshAuthentication.jwtToken;

              if (thisToken) {
                localStorage.setItem('token', thisToken);
                const login = await fetch(`${constants.host}/login`, {
                  method: 'POST',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({ token: thisToken }),
                });

                console.log(`login request`, login);
              }
            });
          }
          if (q.req) {
            const redirect = `${window.location.origin}/${decodeURIComponent(sanitize(q.req))}`;

            window.location = redirect;
          }
        } catch (e) {
          console.log('token response error:', e);
        }

        // window.location = `${wl.protocol}//${wl.host}/admin`;
      }
    }
  });

  const attemptLogin = async () => {
    try {
      const jwtMutation = await client.mutate({
        mutation: gql`
        mutation {
          authenticate(
            input: { username: "${username}", password: "${password}" }
          ) {
            jwtToken
          }
        }
      `,
      });

      console.log(jwtMutation.data.authenticate.jwtToken);

      token = jwtMutation.data.authenticate.jwtToken;
    } catch (e) {
      console.log('attempt login error on login.svelte', e);
    }

    if (token) {
      localStorage.setItem('token', token);
      const tokenResponse = await fetch('/login', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });
      console.log('loginResponse', tokenResponse, jwtDecode(token));
    } else {
      error = `There was an error logging you in`;
    }
  };

  const logout = async () => {
    await fetch('/logout', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    localStorage.removeItem('token');
    client.clearStore();
    token = null;
  };

  const d = new Date();
  const year = d.getFullYear();
</script>

<div class="form-signin">

  <div class="display-4">FEA</div>

  {#if token}
    <h1 class="h3 mb-3 font-weight-normal">You logged in.</h1>

    <a class="btn btn-lg btn-primary btn-success" href="/admin/">Go to Dashboard</a>

    <button class="btn btn-lg btn-primary btn-danger" on:click={logout}>Log Out</button>
  {:else}
    {#if error}
      <div>{error}</div>
    {/if}
    <h1 class="h3 mb-3 font-weight-normal">Please sign in</h1>
    <label for="inputUser" class="sr-only">User Name</label>
    <input
      type="username"
      id="inputUser"
      class="form-control mb-2"
      placeholder="Username"
      bind:value={username}
      required />
    <label for="inputPassword" class="sr-only">Password</label>
    <input
      type="password"
      id="inputPassword"
      class="form-control"
      placeholder="Password"
      bind:value={password}
      required />
    <button class="btn btn-lg btn-primary btn-block" on:click={attemptLogin}>Sign in</button>
  {/if}
  <p class="mt-5 mb-3 text-muted">&copy; {year} fe.pubay.com</p>

</div>
