// eslint-disable-next-line no-undef
const isDev = ENVIRONMENT === 'development';

let host;

let graphql;

if (isDev) {
  // WARN: hardcoded ports
  console.log('Using dev constants');
  host = 'http://localhost:40210';
  graphql = 'http://localhost:40210/graphql';
} else {
  console.log('Using production constants');
  host = `https://fe.pubay.com`;
  graphql = 'https://fe.pubay.com/graphql';
}

module.exports = {
  host,
  graphql,
  isDev,
};
