/* eslint-disable node/no-unsupported-features/es-syntax */
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { Observable, ApolloLink } from 'apollo-link';

import jwtDecode from 'jwt-decode';
import constants from '../constants';

let jwt;
let token;

let refreshing = false;

const waitUntilNotRefreshing = (count = 1) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      if (!refreshing) {
        resolve();
      } else {
        resolve(waitUntilNotRefreshing(count + 1));
      }
    }, 100 * count);
  });
};

const fetchNewToken = async () => {
  if (refreshing) {
    await waitUntilNotRefreshing();
    return { token };
  } else {
    refreshing = true;
    const response = await fetch(constants.graphql, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: `mutation { 
                              refreshAuthentication(input: { username: "${jwt.username}", token: "${jwt.refresh_token}" }) {
                              jwtToken 
                              }
                            }`,
      }),
    });

    const json = await response.json();
    const thisToken = json.data.refreshAuthentication.jwtToken;
    let error = null;

    if (thisToken) {
      localStorage.setItem('token', thisToken);
      token = thisToken;
      await fetch(`${constants.host}/login`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });
    } else {
      error = new Error('null token');
      error.name = 'JWT Error: null token';
      error.response = response;
      error.statusCode = response.status;
      error.result = json;
    }
    refreshing = false;
    return { token: thisToken, error };
  }
};

const authLink = setContext((_, { headers }) => {
  const lsToken = localStorage.getItem('token');
  if (!token && lsToken) {
    token = lsToken;
  }

  if (token) {
    jwt = jwtDecode(token);
    // console.log(jwt);
    // console.log(
    //   'refresh logic',
    //   Date.now(),
    //   jwt.exp * 1000 - 1000 * 60 * 5,
    //   Date.now() > jwt.exp * 1000 - 1000 * 60 * 5,
    // );
    const needsRefresh = Date.now() > jwt.exp * 1000 - 1000 * 60 * 5;
    if (needsRefresh) {
      console.log(
        'refreshing token',
        Date.now(),
        jwt.exp * 1000 - 1000 * 60 * 5,
        Date.now() > jwt.exp * 1000 - 1000 * 60 * 5,
      );
      return fetchNewToken().then(({ token, error }) => {
        if (error) {
          alert(JSON.stringify({ msg: 'authLink refresh', error }));
          console.log('authLink refresh', error);
        }
        const theseHeaders = {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        };
        return { headers: theseHeaders };
      });
    } else {
      headers = {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      };
      return { headers };
    }
  }
});

let jwtExpired = false;

export const client = new ApolloClient({
  link: ApolloLink.from([
    // payload => {
    //     console.log(payload);
    // },
    authLink,
    onError((payload) => {
      const { graphQLErrors, networkError, operation, forward } = payload;
      console.log(payload);
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
          if (message.includes('jwt expired') || message.includes('null token')) {
            jwtExpired = true;
            console.log(
              `JWT Expired: [GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            );
          } else {
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
                locations,
              )}, Path: ${path}`,
            );
          }
        });
      if (networkError) {
        if (networkError.statusCode === 401) {
          jwtExpired = true;
        } else {
          console.log(`[Network error]: ${networkError}`, networkError.statusCode);
        }
      }
      console.log('jwtExpired', jwtExpired);
      if (jwtExpired) {
        return new Observable(async (observer) => {
          try {
            fetchNewToken
              .then(({ error, token }) => {
                if (error) {
                  observer.error(error);
                }
                operation.setContext(({ headers = {} }) => ({
                  headers: {
                    // Re-add old headers
                    ...headers,
                    // Switch out old access token for new one
                    authorization: `Bearer ${token}`,
                  },
                }));
              })
              .then(() => {
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };

                // Retry last failed request
                return forward(operation).subscribe(subscriber);
              })
              .catch((error) => {
                console.log(`caught error on refresh`, error);
                observer.error(error);
              });
          } catch (e) {
            console.log(`Fetch token error`, e);
            observer.error(e);
          }
        });
      }
    }),
    new HttpLink({
      uri: constants.graphql,
      credentials: 'same-origin',
    }),
  ]),
  cache: new InMemoryCache(),
});
